define("discourse/plugins/chat/discourse/helpers/format-chat-date", ["exports", "@ember/template", "discourse/models/user", "discourse-common/lib/get-url", "discourse-i18n"], function (_exports, _template, _user, _getUrl, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatChatDate;
  function formatChatDate(message) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const currentUser = _user.default.current();
    const tz = currentUser ? currentUser.user_option.timezone : moment.tz.guess();
    const date = moment(new Date(message.createdAt), tz);
    const title = date.format(_discourseI18n.default.t("dates.long_with_year"));
    const display = options.mode === "tiny" ? date.format(_discourseI18n.default.t("dates.time_short")) : date.format(_discourseI18n.default.t("dates.time"));
    if (message.staged) {
      return (0, _template.htmlSafe)(`<span title='${title}' tabindex="-1" class='chat-time'>${display}</span>`);
    } else {
      let url;
      if (options.threadContext) {
        url = (0, _getUrl.default)(`/chat/c/-/${message.channel.id}/t/${message.thread.id}/${message.id}`);
      } else {
        url = (0, _getUrl.default)(`/chat/c/-/${message.channel.id}/${message.id}`);
      }
      return (0, _template.htmlSafe)(`<a title='${title}' tabindex="-1" class='chat-time' href='${url}'>${display}</a>`);
    }
  }
});